import { Locale } from '@fxtr/i18n';

export const TRUSTPILOT_TEMPLATE_ID = '5419b6ffb0d04a076446a9af';
export const TRUSTPILOT_BUSINESS_UNIT_ID = '59302da80000ff0005a3a960';

export const TRUSTPILOT_TEMPLATE_ID_FR = '5419b732fbfb950b10de65e5';
export const TRUSTPILOT_BUSINESS_UNIT_ID_FR = '63ea06236e7b78a3eb777792';

export const trustpilotConfigMap = {
  [Locale.EN_GB]: {
    templateId: TRUSTPILOT_TEMPLATE_ID,
    businessUnitId: TRUSTPILOT_BUSINESS_UNIT_ID,
  },
  [Locale.FR_FR]: {
    templateId: TRUSTPILOT_TEMPLATE_ID_FR,
    businessUnitId: TRUSTPILOT_BUSINESS_UNIT_ID_FR,
  },
};
