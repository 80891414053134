import classNames from 'classnames';
import styles from './index.module.scss';

interface ScrollIndicatorProps {
  readonly items: number;
  readonly current?: number;
  readonly className?: string;
  readonly indicatorClick?: (step: number) => void;
}
// @QUESTION should we guard current value to be 1 >= current <= items?
export function ScrollIndicator({
  items,
  current = 1,
  indicatorClick,
  className,
}: ScrollIndicatorProps): JSX.Element {
  const dots = Array.from({
    length: items,
  });

  return (
    <ol className={classNames(ScrollIndicator.displayName, styles.scrollIndicator, className)}>
      {dots.map((_item, i) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={
            classNames(styles.scrollIndicatorItem, current === i + 1 && [styles.current, 'current']) ||
            undefined
          }
          onClick={indicatorClick && (() => indicatorClick(i))}
        />
      ))}
    </ol>
  );
}
ScrollIndicator.displayName = 'ScrollIndicator';
