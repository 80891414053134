'use client';

import React, { useRef, useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import type { DebouncedFunc } from 'lodash';
import { debounce } from 'lodash';
import { ArrowButton } from '$src-components/atoms/ArrowButtons';
import { ScrollIndicator } from '$src-components/atoms/ScrollIndicator';
import type { AnyObject } from '$util/types';
import vars from '$util/theme/vars';
import styles from './index.module.scss';
import { getCurrentCarouselItemIndex } from './helpers';

export interface CarouselProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode[];
  desktopVisibleItems?: number;
  peek?: number;
  mobilePeek?: number;
  mobileOffset?: number;
  desktopOffset?: number;
  desktopItemMaxWidth?: number;
  gap?: number;
  desktopGap?: number;
  mobileSidePadding?: number;
  clickableIndicators?: boolean;
  noButtons?: boolean;
  /**
   * Auto scroll interval in milliseconds. If set to true, the interval will be 10 seconds.
   * Must be >= 3000. If a lower number is supplied, it will default to 10 seconds (i.e. 10000).
   */
  auto?: number | boolean;
  onNext?: () => void;
  onPrev?: () => void;
  onItemChange?: (index: number) => void;
}

export function Carousel({
  children,
  desktopVisibleItems = 2,
  peek = 3,
  mobilePeek = 24,
  mobileOffset = 0,
  desktopOffset = 0,
  desktopItemMaxWidth,
  gap = 8,
  desktopGap = 16,
  mobileSidePadding,
  clickableIndicators = false,
  noButtons = false,
  auto,
  onNext,
  onPrev,
  onItemChange,
  style,
  className,
}: CarouselProps): JSX.Element {
  const carouselViewportRef = useRef<HTMLDivElement>(null);
  const [currentItem, setCurrentItem] = useState(1);
  const overrideStyles = {
    '--desktop-visible-items': desktopVisibleItems,
    '--mobile-offset': `${mobileOffset}px`,
    '--desktop-offset': `${desktopOffset}px`,
    '--desktop-item-max-width': desktopItemMaxWidth ? `${desktopItemMaxWidth}px` : 'unset',
    '--gap': `${gap}px`,
    '--desktop-gap': `${desktopGap}px`,
    '--mobile-side-padding': `${mobileSidePadding ?? 20}px`,
  };
  const cssVariables: AnyObject = {
    '--mobile-peek': `${mobilePeek}px`,
    '--peek': `${peek}px`,
  };
  const consolidatedStyles = { ...cssVariables, ...style, ...overrideStyles };

  const carouselItemRelevantWidth = useCallback(() => {
    if (!carouselViewportRef.current) return undefined;

    const target = carouselViewportRef.current;
    const carouselRibbon = Array.from(target.children).at(0);
    const firstCarouselItem = carouselRibbon?.children[0];
    if (!carouselRibbon || !firstCarouselItem) return undefined;
    const carouselViewportLessThanMedium = target.clientWidth < vars.mediaQueryBreakpoints.medium - 80;
    const currentGap = carouselViewportLessThanMedium ? `${gap}px` : `${desktopGap}px`;
    const { width: carouselItemWidth } = window.getComputedStyle(firstCarouselItem);
    const width =
      parseInt(carouselItemWidth, 10) +
      parseInt(currentGap, 10) / (carouselViewportLessThanMedium ? 1 : desktopVisibleItems) +
      (carouselViewportLessThanMedium ? mobilePeek : peek);
    return width;
  }, [desktopGap, desktopVisibleItems, gap, mobilePeek, peek]);

  useEffect(() => {
    if (!carouselViewportRef.current) return undefined;

    const target = carouselViewportRef.current;
    /**
     * @NOTE this is bad for runtime performance
     *
     * scrollend was previously used with feature sensing would have been
     * preferred, but this results in animations and transitions of the slides
     * and indicators occurring after the slide is settled.
     *
     * The degradation is minimal, does not affect CWV but may result in choppy
     * scrolling and animations of the carousel under some circumstances and
     * devices. The degradation was decided to be acceptable for the aesthetics
     * gained.
     * */
    const eventType = 'scroll';

    const handleScrollIndicator = () => {
      const carouselRibbon = Array.from(target.children).at(0);
      const firstCarouselItem = carouselRibbon?.children[0];
      const relevantWidth = carouselItemRelevantWidth();
      if (!carouselRibbon || !firstCarouselItem || !relevantWidth) return;

      const { width: ribbonWidth } = window.getComputedStyle(carouselRibbon);

      const newItemIndex = getCurrentCarouselItemIndex(
        target,
        relevantWidth,
        parseInt(ribbonWidth, 10),
        target.clientWidth < vars.mediaQueryBreakpoints.medium - 80 ? mobileOffset : desktopOffset
      );

      setCurrentItem(newItemIndex);

      onItemChange?.(newItemIndex - 1); // Call the onItemChange callback with 0-based index
    };

    target.addEventListener(eventType, handleScrollIndicator, false);

    const scrollEndSupport = 'scrollend' in target;
    const restartScrollSnap = () => {
      target.classList.remove('suspend-snap');
    };
    let handleSuspendSnap: () => void | DebouncedFunc<() => void>;

    if (scrollEndSupport) {
      handleSuspendSnap = restartScrollSnap;
      target.addEventListener('scrollend', handleSuspendSnap, false);
    } else {
      /**
       * @NOTE this implementation is a hack for safari because it does not support
       * scrollend event. This is a workaround to allow it to work with the scroll
       * event instead.
       */
      handleSuspendSnap = debounce(restartScrollSnap, 500);

      target.addEventListener('scroll', handleSuspendSnap, false);
    }

    return () => {
      target.removeEventListener(eventType, handleScrollIndicator, false);
      if (!scrollEndSupport) {
        target.removeEventListener('scroll', handleSuspendSnap, false);
        (handleSuspendSnap as DebouncedFunc<() => void>).cancel();
      } else {
        target.removeEventListener('scrollend', handleSuspendSnap, false);
      }
    };
  }, [
    carouselItemRelevantWidth,
    carouselViewportRef,
    currentItem,
    desktopGap,
    desktopOffset,
    gap,
    mobileOffset,
    mobilePeek,
    onItemChange,
    peek,
  ]);

  const move = useCallback(
    (direction: 'left' | 'right', steps: number = 1) => {
      const target = carouselViewportRef?.current;
      const carouselItem = target?.children[0]?.children[0];
      const relevantWidth = carouselItemRelevantWidth();
      if (!carouselItem || !relevantWidth) return;

      if (steps > 1) {
        target.classList.add('suspend-snap');
      }
      target.scrollBy(relevantWidth * steps * (direction === 'left' ? -1 : 1), 0);
      if (direction === 'left' && onPrev) {
        onPrev();
      } else if (direction === 'right' && onNext) {
        onNext();
      }
    },
    [carouselItemRelevantWidth, onNext, onPrev]
  );

  // auto scroll
  useEffect(() => {
    if (!auto || !carouselViewportRef.current) return undefined;

    const viewport = carouselViewportRef.current;
    const intervalTime = typeof auto === 'number' && auto >= 3000 ? auto : 10000;
    let timer: NodeJS.Timeout | null = null;

    const startAutoScroll = () => {
      timer = setInterval(() => {
        setCurrentItem((prev) => {
          if (prev < children.length) {
            move('right');
            return prev + 1;
          }
          move('left', children.length - 1);
          return 1; // Reset to first item
        });
      }, intervalTime);
    };

    const stopAutoScroll = () => {
      if (timer) clearInterval(timer);
    };

    // Start scrolling
    startAutoScroll();

    // Pause on hover
    viewport.addEventListener('mouseenter', stopAutoScroll, false);
    viewport.addEventListener('mouseleave', startAutoScroll, false);

    // Pause on touch
    const supportsTouch = 'ontouchstart' in window;

    if (supportsTouch) {
      viewport.addEventListener('touchstart', stopAutoScroll, false);
      viewport.addEventListener('touchend', startAutoScroll, false);
    }

    return () => {
      stopAutoScroll();
      viewport.removeEventListener('mouseenter', stopAutoScroll);
      viewport.removeEventListener('mouseleave', startAutoScroll);

      if (supportsTouch) {
        viewport.removeEventListener('touchstart', stopAutoScroll, false);
        viewport.removeEventListener('touchend', startAutoScroll, false);
      }
    };
  }, [children.length, move, auto]);

  const showArrows = children.length > 2;

  return (
    <div
      style={consolidatedStyles}
      className={classNames(Carousel.displayName, styles.carouselContainer, className)}
    >
      {!noButtons && (
        <>
          <ArrowButton
            className={classNames(
              styles.carouselArrow,
              (!showArrows || currentItem < 2) && styles.carouselArrowOff
            )}
            direction="left"
            onClick={() => move('left')}
          />
          <ArrowButton
            className={classNames(
              styles.carouselArrow,
              (!showArrows || currentItem >= children.length) && styles.carouselArrowOff
            )}
            direction="right"
            onClick={() => move('right')}
          />
        </>
      )}
      <div ref={carouselViewportRef} className={styles.carouselViewPort}>
        <ul className={styles.carouselRibbon}>
          {children.map((child, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classNames(
                'carouselItem',
                styles.carouselItem,
                index === currentItem - 1 ? 'selected' : undefined
              )}
            >
              {child}
            </li>
          ))}
        </ul>
      </div>
      <ScrollIndicator
        items={children.length}
        current={currentItem}
        className={classNames(styles.carouselIndicator, children.length < 3 && styles.carouselIndicatorOff)}
        indicatorClick={
          clickableIndicators
            ? (step) => {
                // left or right
                if (step < currentItem - 1) {
                  move('left', currentItem - 1 - step || 1);
                } else if (step > currentItem - 1) {
                  move('right', step - (currentItem - 1) || 1);
                }
              }
            : undefined
        }
      />
    </div>
  );
}
Carousel.displayName = 'Carousel';
