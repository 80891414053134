'use client';

import type { HTMLAttributes } from 'react';
import React, { useEffect, useRef } from 'react';
import Script from 'next/script';
import classNames from 'classnames';
import { Locale } from '@fxtr/i18n/locale';
import { trustpilotConfigMap } from '$constants/trustpilot';
import styles from './index.module.scss';

declare global {
  interface Window {
    Trustpilot?: {
      loadFromElement: (el: HTMLElement | null) => void;
    };
  }
}

const TrustpilotScriptLoadedEvent = 'TrustpilotScriptLoadedEvent';

export interface TrustPilotProps extends HTMLAttributes<HTMLDivElement> {
  locale: Locale;
  width?: string;
  height?: string;
  theme?: string;
}

export function TrustPilot({
  height,
  width,
  theme = 'light',
  locale = Locale.EN_GB,
  className,
}: TrustPilotProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  const templateIdConfig = trustpilotConfigMap[locale].templateId;
  const businessUnitIdConfig = trustpilotConfigMap[locale].businessUnitId;

  /**
   * Multiple components on the page should all init the widget after script loads.
   */
  useEffect(() => {
    const handleScriptLoad = () => window.Trustpilot?.loadFromElement(ref.current);

    if (window.Trustpilot) {
      // script already loaded
      handleScriptLoad();
    } else {
      // for when script will load
      window.addEventListener(TrustpilotScriptLoadedEvent, handleScriptLoad);
    }

    return () => window.removeEventListener(TrustpilotScriptLoadedEvent, handleScriptLoad);
  }, []);

  return (
    <div
      ref={ref}
      className={classNames(styles.component, TrustPilot.displayName, className)}
      data-locale={locale}
      data-template-id={templateIdConfig}
      data-businessunit-id={businessUnitIdConfig}
      data-style-height={height || '60px'}
      data-style-width={width}
      data-theme={theme}
    >
      {/* This will load the script only once in the page where component is used (even if multiple times on same page) */}
      <Script
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="lazyOnload"
        onLoad={() => window.dispatchEvent(new Event(TrustpilotScriptLoadedEvent))}
      />
    </div>
  );
}

TrustPilot.displayName = 'TrustPilot';
